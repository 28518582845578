<template>
  <v-app>
    <dashboard-core-app-bar class="no-print" />

    <dashboard-core-drawer class="no-print" />

    <dashboard-core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>

<style>
@media print {
  .page-break,
  .break-before,
  .page-break-before {
    page-break-before: always;
  }

  .break-after,
  .page-break-after {
    page-break-after: always;
  }

  .avoid-break-inside {
    page-break-inside: avoid;
  }

  table {
    page-break-inside: avoid;
  }

  .no-print {
    display: none !important;
  }

  .v-main {
    padding: 0 !important;
  }
}
</style>
